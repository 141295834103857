import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Metagroup } from 'app/assetgroup/metagroup/metagroup';

@Component({
  selector: 'xcm-metagroup-list',
  templateUrl: './metagroup-list.component.html',
  styleUrls: ['./metagroup-list.component.css'],
  standalone: false
})
export class MetagroupListComponent {
  @Input() metagroups: Metagroup[];
  @Output() onDetails: EventEmitter<Metagroup> = new EventEmitter();
  constructor() {}

  details(metagroup: Metagroup) {
    this.onDetails.emit(metagroup);
  }
}
