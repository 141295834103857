import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Metagroup } from 'app/assetgroup/metagroup/metagroup';
import { AuthorizationService } from 'app/core/authorization.service';
import { AuthenticationService } from 'app/login/authentication.service';
import { AppState } from 'app/reducers/reducers';
import { AuthorizationData } from 'app/shared/models/authorization';
import { Scope } from 'app/shared/models/scope';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'xcm-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false
})
export class HeaderNavComponent implements OnInit {
  authorizations$: Observable<AuthorizationData>;
  scope$: Observable<Scope>;
  metagroup$: Observable<Metagroup>;

  isexpanded: boolean;

  constructor(
      private store: Store<AppState>, private authService: AuthenticationService,
      private authorizationService: AuthorizationService, private router: Router) {}

  ngOnInit() {
    this.isexpanded = false;
    this.scope$ = this.store.select('scope').pipe(
      filter((scopes) => !!scopes && scopes.length > 0), map((scopes) => scopes[0]));
    this.metagroup$ = this.store.pipe(select('metagroup'));

    this.authorizations$ =
        this.authorizationService.getBasicAuthorizations().pipe(map(authorizations => {
          const authorizationData = new AuthorizationData();
          authorizations.forEach((authorization) => {
            authorizationData[authorization.label as keyof AuthorizationData] = authorization.allow;
          });
          return authorizationData;
        }));
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  get userInfo() {
    return this.authService.idTokenPayload;
  }

  collapse() {
    this.isexpanded = !this.isexpanded;
  }

  logout() {
    this.router.navigate(['/login']);
  }
}
