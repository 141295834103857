<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <div class="mb-3 row">
        <label for="scope" class="col-sm-2 col-form-label" i18n="@@profile.scope">Scope</label>
        <div class="col-10">
            <ng-select id="scope" formControlName="scope" [class.is-valid]="showSuccessFeedback('scope')"
             [class.is-invalid]="showErrorFeedback('scope', 'required')"
            class="col-sm-10 custom-select">
                <ng-option disabled selected value i18n="@@selectscope"> -- Select a scope -- </ng-option>
                <ng-option *ngFor="let scope of scopes" [value]="scope.label"> {{ getScope(scope) }}</ng-option>
            </ng-select>
            <div *ngIf="showErrorFeedback('label', 'required')" class="invalid-feedback">Scope is required</div>                        
        </div>
    </div>

    <div class="mb-3 row">
        <label for="label" class="col-sm-2 col-form-label" i18n="@@profile.label">Label</label>
        <div class="col-10">
            <input formControlName="label" id="label" type="text"
            class="form-control"
            [class.is-valid]="showSuccessFeedback('label')"
            [class.is-invalid]="showErrorFeedback('label', 'required')"
            placeholder="label"
            aria-describedby="helplabel"/>
            <div *ngIf="showErrorFeedback('label', 'required')" class="invalid-feedback">Label is required</div>            
        </div>
    </div>

    <div class="mb-3 row">
        <label for="system" class="col-sm-2 col-form-label" i18n="@@profile.system">System</label>
        <div class="col-10">
            <input formControlName="system" id="system" type="text"
            class="form-control"
            [class.is-valid]="showSuccessFeedback('system')"
            [class.is-invalid]="showErrorFeedback('system', 'required')"
            placeholder="system"
            aria-describedby="helpsystem"/>
            <div *ngIf="showErrorFeedback('system', 'required')" class="invalid-feedback">System is required</div>
        </div>
    </div>

    <div class="mb-3 row">
        <label for="subsystem" class="col-sm-2 col-form-label" i18n="@@profile.system">SubSystem</label>
        <div class="col-10">
            <input formControlName="subsystem" id="subsystem" type="text"
            class="form-control"
            placeholder="subsystem"
            aria-describedby="helpsystem"/>
        </div>
    </div>

    <br>
    <div class="card mb-3">
        <div class="card-header">
                <h5>Textboxes <button type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="addTextbox()"><i class="fa fa-plus"></i></button></h5>
        </div>
        <div class="card-body">
            <div formArrayName="textboxes">
                <div *ngFor="let textbox of form.get('textboxes')?.controls; let i=index">
                    <div class="mb-5 card">
                    <div class="card-header">
                        <h5>Textbox {{i + 1}} <button type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="removeTextbox(i)"><i class="fa fa-close"></i></button></h5>
                    </div>
                    <div class="card-body" [formGroupName]="i">
                        <xcm-textbox-profile [form]="form.get('textboxes')?.controls[i]"></xcm-textbox-profile>
                    </div>
                    </div>
                </div>
            </div>
            <button *ngIf="form.get('textboxes')?.controls.length>0" type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="addTextbox()"><i class="fa fa-plus"></i></button>            
        </div>
    </div>


    <div class="card mb-3">
        <div class="card-header">
                <h5>Checkboxes <button type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="addCheckbox()"><i class="fa fa-plus"></i></button></h5>
        </div>
        <div class="card-body">
            <div formArrayName="checkboxes">
                <div *ngFor="let checkbox of form.get('checkboxes')?.controls; let i=index">
                    <div class="mb-5 card">
                        <div class="card-header">
                            <h5>Checkbox {{i + 1}} <button type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="removeCheckbox(i)"><i class="fa fa-close"></i></button></h5>
                        </div>
                        <div class="card-body" [formGroupName]="i">
                            <xcm-checkbox-profile [form]="form.get('checkboxes')?.controls[i]"></xcm-checkbox-profile>
                        </div>
                    </div>
                </div>
            </div>
            <button *ngIf="form.get('checkboxes')?.controls.length>0" type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="addCheckbox()"><i class="fa fa-plus"></i></button>            
        </div>
    </div>


    <div class="card mb-3">
        <div class="card-header">
                <h5>Radios <button type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="addRadio()"><i class="fa fa-plus"></i></button></h5>
        </div>
        <div class="card-body">
            <div formArrayName="radios">
                <div *ngFor="let radio of form.get('radios')?.controls; let i=index">
                    <div class="mb-5 card">
                        <div class="card-header">
                            <h5>Radio {{i + 1}} <button type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="removeRadio(i)"><i class="fa fa-close"></i></button></h5>
                        </div>
                        <div class="card-body" [formGroupName]="i">
                            <xcm-dropdown-profile [form]="form.get('radios')?.controls[i]" ></xcm-dropdown-profile>
                        </div>
                    </div>
                </div>
            </div>
            <button *ngIf="form.get('radios')?.controls.length>0" type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="addRadio()"><i class="fa fa-plus"></i></button>                        
        </div>
    </div>


    <div class="card mb-3">
        <div class="card-header">
                <h5>DropDowns <button type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="addDropdown()"><i class="fa fa-plus"></i></button></h5>
        </div>
        <div class="card-body">
            <div formArrayName="dropdowns">
                <div *ngFor="let dropdown of form.get('dropdowns')?.controls; let i=index">
                    <div class="mb-5 card">
                        <div class="card-header">
                            <h5>Dropdown {{i + 1}} <button type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="removeDropdown(i)"><i class="fa fa-close"></i></button></h5>
                        </div>
                        <div class="card-body" [formGroupName]="i">
                            <xcm-dropdown-profile [form]="form.get('dropdowns')?.controls[i]"></xcm-dropdown-profile>
                        </div>
                    </div>
                </div>
            </div>
            <button *ngIf="form.get('dropdowns')?.controls.length>0" type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="addDropdown()"><i class="fa fa-plus"></i></button>            
        </div>
    </div>

    <div class="card mb-3">
        <div class="card-header">
                <h5>MultiSelects <button type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="addMultiselect()"><i class="fa fa-plus"></i></button></h5>
        </div>
        <div class="card-body">
            <div formArrayName="multiselects">
                <div *ngFor="let multiselect of form.get('multiselects')?.controls; let i=index">
                    <div class="mb-5 card">
                        <div class="card-header">
                            <h5>Multiselect {{i + 1}} <button type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="removeMultiselect(i)"><i class="fa fa-close"></i></button></h5>
                        </div>
                        <div class="card-body" [formGroupName]="i">
                            <xcm-dropdown-profile [form]="form.get('multiselects')?.controls[i]"></xcm-dropdown-profile>
                        </div>
                    </div>
                </div>
            </div>
            <button *ngIf="form.get('multiselects')?.controls.length>0" type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="addMultiselect()"><i class="fa fa-plus"></i></button>            
        </div>
    </div>
    <div class="pull-right">
        <button type="button"  class="btn btn-outline-primary mb-2 mb-md-0 me-2 test-cancel" (click)="onCancelled()" i18n="@@profile.cancel">Cancel</button>
        <button type="submit"  [disabled]="!form.valid" class="btn btn-primary mb-2 mb-md-0 me-2 test-save" i18n="@@profile.save">Save</button>        
    </div>
 </form>
