<ng-container *ngIf="scopes$ | async as scopes">
<ng-container *ngIf="scopes.length>0">
<div [hidden]="showForm" class="row">
    <div class="col">
        <xcm-scope [scopes]="scopes" (onScopeSelected)="onScopeSelected($event)"></xcm-scope>                
    </div>
</div>

<div class="container">
<div class="row">
    <div class="col">
        <div class="pull-right">
                <button *ngIf="!showForm" type="button" (click)="create()" class="btn btn-primary mb-2 mb-md-0 me-2"><i class="fa fa-plus fa-fw test-create-asset-profile" aria-hidden="true"></i>Create Asset Profile</button>            
                <button *ngIf="showForm" type="button" (click)="cancel()" class="btn btn-outline-primary mb-2 mb-md-0 me-2 test-assetprofile-cancel"><i class="fa fa-close fa-fw" aria-hidden="true"></i>Cancel</button>        
                
        </div>
    </div>
</div>
</div>


<div *ngIf="!showForm" class="row top-buffer">
    <div class="col-md-12">
        <xcm-profile-list  [profiles]="profiles$ | async" (onDelete)="delete($event)" (onEdit)="edit($event)" ></xcm-profile-list>                
        
    </div>
</div>

<div *ngIf="showForm" class="row top-buffer">
    <div class="container">
    <div class="row justify-content-center">
    <div class="col-10">
        <xcm-profile-form  [scopes]="scopes" [assetprofile]="assetprofile" (onCancel)="cancel()" (onSave)="onSave($event)"></xcm-profile-form>    
    </div>
    </div>
    </div>
</div>
</ng-container>
</ng-container>