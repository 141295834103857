import { Action } from '@ngrx/store';
import { Scope } from 'app/shared/models/scope';

export const CHANGE_SCOPE = 'CHANGE_SCOPE';
export const RESET_SCOPE = 'RESET_SCOPE';

export class ChangeScope implements Action {
  readonly type = CHANGE_SCOPE;
  constructor(public payload: Scope) {}
}

export class ResetScope implements Action {
  readonly type = RESET_SCOPE;
}

export type All = ChangeScope|ResetScope;
