
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from 'app/core/error-hanlder.service';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { Member } from '../assetgroup/members/member';
import { Metagroup } from '../assetgroup/metagroup/metagroup';


interface MetagroupResponse {
  data: Metagroup[];
}

interface MemberResponse {
  data: Member[];
}

@Injectable()
export class MetagroupService {
  private readonly apiUrl: string = '/v1/xcm/metagroups';


  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {}

  getMetagroups(scope: string): Observable<Metagroup[]> {
    return this.http.get<MetagroupResponse>(`${this.apiUrl}?scope=${scope}`)
      .pipe(map((res) => res.data), catchError(this.errorHandlerService.handleError));
  }

  getMembers(metagroupId: string, assetgroupId: string): Observable<Member[]> {
    return this.http
      .get<MemberResponse>(
        `${this.apiUrl}/${metagroupId}/members?filter[assetgroup.id][$ne]=${assetgroupId}`)
      .pipe(
        filter(res => res !== null), map(res => res.data || res),
        catchError(this.errorHandlerService.handleError));
  }
}
