import * as ScopeActions from 'app/reducers/scope.actions';
import { Scope } from 'app/shared/models/scope';

export type Action = ScopeActions.All;
export type State = Scope[];

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ScopeActions.CHANGE_SCOPE: {
      return [action.payload];
    }
    case ScopeActions.RESET_SCOPE: {
      return [];
    }
    default: { return state; }
  }
}
