<div [formGroup]="form">
    <div class="mb-3 row">
      <label for="id" class="col-2 col-form-label" i18n="@@option.id">Identifier</label>
      <div class="col-10">
        <input formControlName="id" id="id" type="text"
        class="form-control"
        [class.is-valid]="showSuccessFeedback('id')"
        [class.is-invalid]="showGlobalErrorFeedback('id')"
        placeholder="identifier"
        aria-describedby="helpid"/>
        <div *ngIf="showErrorFeedback('id', 'required')" class="invalid-feedback">Identifier is required</div>                
        <div *ngIf="showErrorFeedback('id', 'pattern')" class="invalid-feedback">Identifier should start with a letter and can only contains alphanumeric characters</div>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="label" class="col-sm-2 col-form-label" i18n="@@option.label">Label</label>
      <div class="col-10">
        <input formControlName="label" id="label" type="text"
        class="form-control"
        [class.is-valid]="showSuccessFeedback('label')"
        [class.is-invalid]="showGlobalErrorFeedback('label')"
        placeholder="label"
        aria-describedby="helplabel"/>
        <div *ngIf="showErrorFeedback('label', 'required')" class="invalid-feedback">Label is required</div>        
      </div>
    </div>

    <div class="mb-3 row">
      <label for="default" class="col-sm-2 col-form-label" i18n="@@option.default">Default</label>  
      <div class="col-10 form-check">
          <label class="form-check-label form-check-nopadding">
            <input formControlName="default" id="default" type="checkbox" 
            class="form-check-input">
            <span>Default</span>
          </label>
      </div>
    </div>
</div>