<div [formGroup]="form">
    <div class="mb-3 row">
      <label for="id" class="col-2 col-form-label" i18n="@@dropdown.id">Identifier</label>
      <div class="col-10">
        <input formControlName="id" id="id" type="text"
        class="form-control"
        [class.is-valid]="showSuccessFeedback('id')"
        [class.is-invalid]="showGlobalErrorFeedback('id')"
        placeholder="identifier"
        aria-describedby="helpid"/>
        <div *ngIf="showErrorFeedback('id', 'required')" class="invalid-feedback">Identifier is required</div>                
        <div *ngIf="showErrorFeedback('id', 'pattern')" class="invalid-feedback">Identifier should start with a letter and can only contains alphanumeric characters</div>                
      </div>
    </div>

    <div class="mb-3 row">
      <label for="label" class="col-sm-2 col-form-label" i18n="@@dropdown.label">Label</label>
      <div class="col-10">
        <input formControlName="label" id="label" type="text"
        class="form-control"
        [class.is-valid]="showSuccessFeedback('label')"
        [class.is-invalid]="showGlobalErrorFeedback('label')"
        placeholder="label"
        aria-describedby="helplabel"/>
        <div *ngIf="showErrorFeedback('label', 'required')" class="invalid-feedback">Label is required</div>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="order" class="col-sm-2 col-form-label" i18n="@@dropdown.order">Order</label>
      <div class="col-10">
        <input formControlName="order" id="order" type="number"
        class="form-control"
        placeholder="order"
        aria-describedby="helporder"/>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="description" class="col-sm-2 col-form-label" i18n="@@dropdown.description">Description</label>
      <div class="col-10">
        <input formControlName="description" id="description" type="text"
        class="form-control"
        placeholder="description"
        aria-describedby="helpdescription"/>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="required" class="col-sm-2 col-form-label" i18n="@@dropdown.required">Required</label>  
      <div class="col-10 form-check">
          <label class="form-check-label form-check-nopadding">
            <input formControlName="required" id="required" type="checkbox" 
            class="form-check-input">
            <span>Required</span>
          </label>
      </div>
    </div>

    <div class="mb-5 card">
        <div class="card-header">
            <h5>Options <button type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="addOption()"><i class="fa fa-plus"></i></button></h5>
        </div>
        <div class="card-body">
          <div formArrayName="options">
              <span *ngIf="form.get('options')?.hasError('singledefault')" class="alert alert-danger">You cannot have more than one default option for single select</span>            
              <span *ngIf="form.get('options')?.hasError('required') || form.get('options')?.hasError('minlength')" class="alert alert-danger">A Choice selection needs at least 2 options</span>
              <span *ngIf="form.get('options')?.hasError('uniqueid')" class="alert alert-danger">Option Identifier should be unique</span>
              <span *ngIf="form.get('options')?.hasError('uniquelabel')" class="alert alert-danger">Option label should be unique</span>     
              <div *ngFor="let option of form.get('options')?.controls; let i=index">
                <div class="mb-5 card">
                  <div class="card-header">
                    <h5>Option {{i + 1}} <button type="button" *ngIf="form.get('options')?.controls.length > 2" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="removeOption(i)"><i class="fa fa-close"></i></button></h5>
                  </div>
                  <div class="card-body" [formGroupName]="i">
                    <xcm-option-profile [form]="form.get('options')?.controls[i]"></xcm-option-profile>
                  </div>
                </div>
              </div>
          </div>
          <button *ngIf="form.get('options')?.controls.length>0" type="button" class="btn df-btn-icononly mb-2 mb-md-0 me-2 float-end" (click)="addOption()"><i class="fa fa-plus"></i></button>          
        </div>
    </div>
</div>