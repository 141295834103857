import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScopeComponent } from 'app/assetgroup/scope/scope.component';

import { DynamicFormQuestionComponent } from './dynamic-form/dynamic-form-question.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicViewQuestionComponent } from './dynamic-view/dynamic-view-question.component';
import { DynamicViewComponent } from './dynamic-view/dynamic-view.component';
import { FilterInputComponent } from './filter-input/filter-input.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule],
  declarations: [
    ScopeComponent, FilterInputComponent, DynamicFormQuestionComponent, DynamicFormComponent,
    DynamicViewComponent, DynamicViewQuestionComponent
  ],
  exports: [
    CommonModule, FormsModule, ReactiveFormsModule, FilterInputComponent, DynamicFormComponent,
    DynamicViewComponent, ScopeComponent
  ]
})
export class SharedModule {
}
