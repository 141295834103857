
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorHandlerService } from 'app/core/error-hanlder.service';
import { Checkbox, ProfileFormModel, Select, SelectOption, Textbox } from 'app/provider-profile/profile-form/profile-form-model';
import { Asset, AssetProfile, ExternalSystem, ProfileElementFormat } from 'app/shared/models/asset';
import { Scope } from 'app/shared/models/scope';
import * as ControlType from 'app/shared/question-model/control-type';
import { Option } from 'app/shared/question-model/option';
import { QuestionBase } from 'app/shared/question-model/question-base';
import { CheckboxQuestion } from 'app/shared/question-model/question-checkbox';
import { DropdownQuestion } from 'app/shared/question-model/question-dropdown';
import { MultiSelectQuestion } from 'app/shared/question-model/question-multi-select';
import { RadiosQuestion } from 'app/shared/question-model/question-radios';
import { TextboxQuestion } from 'app/shared/question-model/question-textbox';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

interface AssetProfileResponse {
  data: AssetProfile[];
}
@Injectable()
export class AssetprofileService {
  private readonly apiUrl = '/v1/xcm/assetprofiles';
  private readonly identifierRegex = /^[a-zA-Z][a-zA-Z0-9]*$/;
  constructor(
      private http: HttpClient, private formBuilder: FormBuilder,
      private errorHandlerService: ErrorHandlerService) {}

  getAssetProfiles(assetgroupId: string): Observable<AssetProfile[]> {
    return this.http.get<AssetProfileResponse>(`${this.apiUrl}?assetgroupId=${assetgroupId}`)
      .pipe(map((res) => res.data || res), catchError(this.errorHandlerService.handleError));
  }

  getAssetProfilesByScope(scope: string): Observable<AssetProfile[]> {
    return this.http.get<AssetProfileResponse>(`${this.apiUrl}?scope=${scope}`)
      .pipe(map((res) => res.data || res), catchError(this.errorHandlerService.handleError));
  }

  getAssetProfilesByScopeAndMetagroup(scope: string, metagroupId: string):
      Observable<AssetProfile[]> {
    return this.http
      .get<AssetProfileResponse>(`${this.apiUrl}?scope=${scope}&metagroupid=${metagroupId}`)
      .pipe(map((res) => res.data || res), catchError(this.errorHandlerService.handleError));
  }

  addAssetProfile(assetprofile: AssetProfile) {
    return this.http.post(`${this.apiUrl}`, assetprofile)
      .pipe(
        tap(this.errorHandlerService.handleWarning),
        catchError(this.errorHandlerService.handleError));
  }

  updateAssetProfile(assetprofile: AssetProfile) {
    return this.http.put(`${this.apiUrl}/${assetprofile.id}`, assetprofile)
      .pipe(
        tap(this.errorHandlerService.handleWarning),
        catchError(this.errorHandlerService.handleError));
  }

  deleteAssetProfile(assetprofileId: string) {
    return this.http.delete(`${this.apiUrl}/${assetprofileId}`)
      .pipe(
        tap(this.errorHandlerService.handleWarning),
        catchError(this.errorHandlerService.handleError));
  }


  getAssetQuestions(asset: Asset): QuestionBase<any>[] {
    const questions = this.getQuestions(asset.profile);
    for (const question of questions) {
      if (asset.payload) {
        const assetpayload =
            asset.payload.find((payload) => payload.profileElementFormatId === question.id);
        if (assetpayload) {
          switch (question.controlType) {
            case ControlType.CHECKBOX:
              question.value = assetpayload.value === 'true';
              break;
            case ControlType.MULTISELECT:
              question.value = assetpayload.value.split(/\s*,\s*/);
              break;
            default:
              question.value = assetpayload.value;
              break;
          }
        }
      }
    }
    return questions;
  }

  getQuestions(assetProfile: AssetProfile): QuestionBase<any>[] {
    const questions: QuestionBase<any>[] = [];
    if (assetProfile.profileElement) {
      for (const profileformat of assetProfile.profileElement) {
        switch (profileformat.controlType) {
          case ControlType.TEXTBOX:
            const textbox = new TextboxQuestion(profileformat);
            if (profileformat.options && profileformat.options.length > 0 &&
                profileformat.options[0].id === profileformat.id &&
                profileformat.options[0].value) {
              textbox.value = profileformat.options[0].value;
            }
            if (profileformat.concealed) {
              textbox.type = 'password';
            }
            questions.push(textbox);
            break;
          case ControlType.CHECKBOX:
            const checkbox = new CheckboxQuestion(profileformat);
            if (profileformat.options && profileformat.options.length > 0 &&
                profileformat.options[0].id === profileformat.id &&
                profileformat.options[0].value) {
              checkbox.value = (profileformat.options[0].value === 'true');
            }
            questions.push(checkbox);
            break;
          case ControlType.DROPDOWN:
            const dropdown = new DropdownQuestion(profileformat);
            let defaultval = null;
            if (profileformat.options && profileformat.options.length > 0 &&
                profileformat.options.some(
                  (value) =>
                    (value.default === true) ? ((defaultval = value.id), true) : false) &&
                defaultval) {
              dropdown.value = defaultval;
            }
            questions.push(dropdown);
            break;
          case ControlType.RADIOS:
            const radios = new RadiosQuestion(profileformat);
            let defaultvalue = null;
            if (profileformat.options && profileformat.options.length > 0 &&
                profileformat.options.some(
                  (value) =>
                    (value.default === true) ? ((defaultvalue = value.id), true) : false) &&
                defaultvalue) {
              radios.value = defaultvalue;
            }
            questions.push(radios);
            break;
          case ControlType.MULTISELECT:
            const multiselect = new MultiSelectQuestion(profileformat);
            let defaultoptions = [];
            if (profileformat.options && profileformat.options.length > 0) {
              defaultoptions = profileformat.options.filter((value) => value.default === true);
              for (const option of defaultoptions) {
                multiselect.value.push(option.id);
              }
            }
            questions.push(multiselect);
            break;

          default:
            questions.push(new TextboxQuestion(profileformat));
            break;
        }
      }
    }

    return questions;
  }

  singleDefaultOptionValidator(array: FormArray) {
    const countchecked = array.controls.filter((item) => item.value.default).length;
    if (countchecked > 1) {
      return {
        singledefault: true
      }
    }
    return null;
  }

  uniqueIdOptionValidator(array: FormArray) {
    const testObject: { [key: string]: any } = {};
    let seenDuplicate = false;
    array.controls.some((item) => {
      const trimmedid = item.value.id.trim();
      if (item.value.id) {
        if (trimmedid in testObject) {
          seenDuplicate = true;
        } else {
          testObject[trimmedid] = item;
        }
      }
      return seenDuplicate;
    });

    if (seenDuplicate) {
      return {
        uniqueid: true
      }
    }
    return null;
  }

  uniqueLabelOptionValidator(array: FormArray) {
    const testObject: { [key: string]: any } = {};
    let seenDuplicate = false;
    array.controls.some((item) => {
      const trimmedlabel = item.value.label.trim();
      if (item.value.label) {
        if (trimmedlabel in testObject) {
          seenDuplicate = true;
        } else {
          testObject[trimmedlabel] = item;
        }
      }
      return seenDuplicate;
    });

    if (seenDuplicate) {
      return {
        uniquelabel: true
      }
    }
    return null;
  }

  regexFormatValidator(control: FormControl) {
    try {
      const a = new RegExp(control.value);
      if (a) {
        return null;
      }
    } catch (e) {
      return { regexformat: true };
    }
    return null;
  }

  getProfileForm(profileFormModel: ProfileFormModel = new ProfileFormModel()) {
    return this.formBuilder.group({
      scope: [profileFormModel.scope || '', [Validators.required]],
      label: [profileFormModel.label || '', [Validators.required]],
      system: [profileFormModel.system || '', [Validators.required]],
      subsystem: [profileFormModel.subsystem || ''],
      textboxes: this.formBuilder.array(
        profileFormModel.textboxes.map((item) => this.getTextboxForm(item))),
      dropdowns: this.formBuilder.array(
        profileFormModel.dropdowns.map((item) => this.getSelectForm(item))),
      checkboxes: this.formBuilder.array(
        profileFormModel.checkboxes.map((item) => this.getCheckboxForm(item))),
      multiselects: this.formBuilder.array(
        profileFormModel.multiselects.map((item) => this.getMultiSelectForm(item))),
      radios:
          this.formBuilder.array(profileFormModel.radios.map((item) => this.getSelectForm(item)))
    });
  }

  getOptionForm(selectOption: SelectOption = new SelectOption()) {
    return this.formBuilder.group({
      id: [selectOption.id || '', [Validators.required, Validators.pattern(this.identifierRegex)]],
      label: [selectOption.label || '', [Validators.required]],
      default: [selectOption.default || false]
    });
  }

  getSelectForm(select: Select = new Select()) {
    const selectform = this.getMultiSelectFormNoValidators(select);
    const options = selectform.get('options');
    if (options) {
      options.setValidators([
        this.singleDefaultOptionValidator, Validators.required, Validators.minLength(2),
        this.uniqueIdOptionValidator, this.uniqueLabelOptionValidator
      ]);
    }
    return selectform;
  }

  getMultiSelectForm(select: Select = new Select()) {
    const selectform = this.getMultiSelectFormNoValidators(select);
    const options = selectform.get('options');
    if (options) {
      options.setValidators([
        Validators.required, Validators.minLength(2), this.uniqueIdOptionValidator,
        this.uniqueLabelOptionValidator
      ]);
    }
    return selectform;
  }

  getMultiSelectFormNoValidators(select: Select = new Select()) {
    return this.formBuilder.group({
      id: [select.id || '', [Validators.required, Validators.pattern(this.identifierRegex)]],
      label: [select.label || '', [Validators.required]],
      description: [select.description || ''],
      order: [select.order || 1],
      required: [select.required || false],
      options: this.formBuilder.array(this.getOptions(select.options))
    });
  }

  getOptions(options: SelectOption[]) {
    let controlConfigs: FormGroup[] = [];
    if (options) {
      controlConfigs = options.map((item) => this.getOptionForm(item));
    } else {
      controlConfigs = [this.getOptionForm(), this.getOptionForm()];
    }
    return controlConfigs;
  }

  getTextboxForm(textbox: Textbox = new Textbox()) {
    return this.formBuilder.group({
      id: [textbox.id || '', [Validators.required, Validators.pattern(this.identifierRegex)]],
      label: [textbox.label || '', [Validators.required]],
      regex: [textbox.regex || '', [this.regexFormatValidator]],
      description: [textbox.description || ''],
      order: [textbox.order || 1],
      default: [textbox.default || ''],
      concealed: [textbox.concealed || false],
      required: [textbox.required || false]
    });
  }

  getCheckboxForm(checkbox: Checkbox = new Checkbox()) {
    return this.formBuilder.group({
      id: [checkbox.id || '', [Validators.required, Validators.pattern(this.identifierRegex)]],
      label: [checkbox.label || '', [Validators.required]],
      description: [checkbox.description || ''],
      order: [checkbox.order || 1],
      default: [checkbox.default || false]
    });
  }

  getControlType(name: string) {
    let controlType = '';
    switch (name) {
      case 'radios':
        controlType = ControlType.RADIOS;
        break;
      case 'multiselects':
        controlType = ControlType.MULTISELECT;
        break;
      case 'dropdowns':
        controlType = ControlType.DROPDOWN;
        break;

      default:
        break;
    }
    return controlType;
  }

  assetProfileToProfileForm(assetProfile: AssetProfile) {
    const profileForm = new ProfileFormModel();
    profileForm.label = assetProfile.label;
    profileForm.system = assetProfile.externalSystem.system;
    profileForm.subsystem = assetProfile.externalSystem.subsystem;
    profileForm.scope = assetProfile.scope.label;
    profileForm.textboxes = assetProfile.profileElement ?
      assetProfile.profileElement
        .filter((elementFormat) => elementFormat.controlType === ControlType.TEXTBOX)
        .map((textboxprofile): Textbox => {
          const textbox: Textbox = new Textbox();
          for (const key in textboxprofile) {
            if (textboxprofile.hasOwnProperty(key) && key !== 'controlType') {
              if (key === 'options') {
                if (textboxprofile.options) {
                  textboxprofile.options.forEach((option) => textbox.default = option.value);
                }
              } else {
                textbox[key] = textboxprofile[key];
              }
            }
          }
          return textbox;
        }) :
      [];

    profileForm.checkboxes = assetProfile.profileElement ?
      assetProfile.profileElement
        .filter((elementFormat) => elementFormat.controlType === ControlType.CHECKBOX)
        .map((checkboxprofile): Checkbox => {
          const checkbox: Checkbox = new Checkbox();
          for (const key in checkboxprofile) {
            if (checkboxprofile.hasOwnProperty(key) && key !== 'controlType') {
              if (key === 'options') {
                if (checkboxprofile.options) {
                  checkboxprofile.options.forEach(
                    (option) => checkbox.default = option.default || false);
                }
              } else {
                checkbox[key] = checkboxprofile[key];
              }
            }
          }
          return checkbox;
        }) :
      [];

    ['radios', 'dropdowns', 'multiselects'].forEach((name) => {
      profileForm[name] = assetProfile.profileElement ?
        assetProfile.profileElement
          .filter((elementFormat) => elementFormat.controlType === this.getControlType(name))
          .map((selectprofile) : Select => {
            const select: Select = new Select();
            for (const key in selectprofile) {
              if (selectprofile.hasOwnProperty(key) && key !== 'controlType') {
                if (key === 'options') {
                  if (selectprofile.options) {
                    select.options = selectprofile.options.map((optionprofile) => {
                      const selectOption = new SelectOption();
                      selectOption.id = optionprofile.id;
                      selectOption.label = optionprofile.value;
                      selectOption.default = optionprofile.default || false;
                      select.options.push(selectOption);
                      return selectOption;
                    });
                  }
                } else {
                  select[key] = selectprofile[key];
                }
              }
            }
            return select;
          }) :
        [];
    });
    return profileForm;
  }

  profileFormToAssetProfile(profileForm: ProfileFormModel) {
    const assetProfile: AssetProfile = new AssetProfile();
    assetProfile.label = profileForm.label;

    const externalSystem: ExternalSystem = new ExternalSystem();
    externalSystem.system = profileForm.system;
    externalSystem.subsystem = profileForm.subsystem;
    assetProfile.externalSystem = externalSystem;

    const scope: Scope = new Scope();
    scope.label = profileForm.scope;
    assetProfile.scope = scope;

    profileForm.textboxes.forEach((item) => {
      const profileElementFormat: ProfileElementFormat = new ProfileElementFormat();
      profileElementFormat.controlType = ControlType.TEXTBOX;

      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          if (key === 'default') {
            const option: Option = new Option();
            option.id = item.id;
            option.value = item.default;
            profileElementFormat.options.push(option);
          } else {
            profileElementFormat[key] = item[key];
          }
        }
      }
      assetProfile.profileElement.push(profileElementFormat);
    });

    profileForm.checkboxes.forEach((item) => {
      const profileElementFormat: ProfileElementFormat = new ProfileElementFormat();
      profileElementFormat.controlType = ControlType.CHECKBOX;

      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          if (key === 'default') {
            const option: Option = new Option();
            option.id = item.id;
            option.default = item.default;
            profileElementFormat.options.push(option);
          } else {
            profileElementFormat[key] = item[key];
          }
        }
      }
      assetProfile.profileElement.push(profileElementFormat);
    });

    ['radios', 'dropdowns', 'multiselects'].forEach((name) => {
      profileForm[name].forEach((item: any) => {
        const profileElementFormat: ProfileElementFormat = new ProfileElementFormat();
        profileElementFormat.controlType = this.getControlType(name);

        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            if (key === 'options') {
              item.options.forEach((opt: SelectOption) => {
                const option: Option = new Option();
                option.id = opt.id;
                option.default = opt.default;
                option.value = opt.label;
                profileElementFormat.options.push(option);
              });

            } else {
              profileElementFormat[key] = item[key];
            }
          }
        }
        assetProfile.profileElement.push(profileElementFormat);
      });
    });
    return assetProfile;
  }
}
