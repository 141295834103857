export class SapConfig {
  static readonly localSap = '1ASIULSARFDD';
  static readonly localLoginPhase = 'dev';
  static readonly usap = {
    DEFAULT: { all_star: '1ASIUSASXCM1A', '1A': '1ASIUSASXCM1A' },
    DEV: { all_star: '1ASIULSARFD', '1A': '1ASIULSARFD' }
  };


  static readonly phaseLetter = {
    DEV: 'D',
    DES: 'S',
    UAT: 'U',
    USR: '',
    PDT: '',
    PRD: '',
    PPT: '',
    MIG: 'M',
    SKL: 'K',
    BTPRD: 'R',
    INT: 'I',
    QRT: 'Q',
    FVT: 'F',
    SUP: 'T',
    AP1: 'A'
  };

  static getPhaseLetter(phase: keyof typeof SapConfig.phaseLetter): string {
    return SapConfig.phaseLetter[phase] || '';
  }
}
