<ng-container *ngIf="assets$ | async as assets">
<xcm-filter-input *ngIf="assets.length>0" [collection]="assets" [filtercallback]="filterCallback" (onChange)="setfilteredAssets($event)"></xcm-filter-input>
<hr *ngIf="assets.length>0"/>
<ng-container *ngIf="filteredAssets$ | async as filteredassets">
<ul class="list-group">
  <li class="list-group-item" *ngFor="let asset of filteredassets">
    <div class="row align-items-center">
      <span class="col-md-4"> {{ asset.profile.label }} </span>        
      <div class="col-md-8">
        <div class="pull-right">
            <button *ngIf="(authorizations$ | async)?.D_ASSET" (click)="onDelete(asset)" class="btn btn-outline-danger mb-2 mb-md-0 me-2 test-delete-assetprofile"><span class="fa fa-trash-can" aria-hidden="true"></span>Delete</button>
            <button *ngIf="(authorizations$ | async)?.R_ASSET" (click)="onDetails(asset)" class="btn btn-outline-primary mb-2 mb-md-0 me-2 test-details-assetprofile"><span class="fa fa-eye" aria-hidden="true"></span>Details</button>   
        </div>
      </div>
    </div>
  </li>
</ul>
</ng-container>
<!--xcm-asset-detail [asset]="selectedAsset"></xcm-asset-detail-->
</ng-container>

