<div class="mb-3 row">
  <label for="selectedscope" class="col-sm-4 col-form-label" i18n="@@choosescope">Choose a scope</label>
  <div class="col-8">
      <ng-select  labelForId="selectedscope" [(ngModel)]="selectedScope"
      (change)="onScopeChange()">
        <ng-option *ngFor="let scope of scopes" [value]="scope">
          {{ scope.label }} - {{ scope.description }}
        </ng-option>
      </ng-select>
  </div>
</div>