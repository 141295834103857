import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProviderProfileModule } from 'app/provider-profile/provider-profile.module';
import { reducers } from 'app/reducers/reducers';
import { AlertComponent } from 'app/shared/alert/alert.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AssetgroupModule } from './assetgroup/assetgroup.module';
import { CoreModule } from './core/core.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { AuthenticationInterceptor } from './login/authentication.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgProgressbar } from 'ngx-progressbar';
import { NgProgressRouter, provideNgProgressRouter } from 'ngx-progressbar/router';
import { GuardsCheckEnd, NavigationEnd } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [AppComponent, AlertComponent, HeaderNavComponent, FooterComponent],
  bootstrap: [AppComponent], imports: [NgbModule, BrowserModule, StoreModule.forRoot(reducers),
    EffectsModule.forRoot([]), CoreModule, AssetgroupModule,
    ProviderProfileModule, AppRoutingModule, NgProgressbar, NgProgressRouter, NgSelectModule,
    CommonModule, FormsModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    provideNgProgressRouter({
      startEvents: [GuardsCheckEnd],
      completeEvents: [NavigationEnd]
    })
  ] })
export class AppModule {
}
