export class Checkbox {
  [key: string]: any;
  id: string;
  label: string;
  order: number;
  description: string;
  default: boolean;
}

export class Textbox {
  [key: string]: any;
  id: string;
  label: string;
  order: number;
  description: string;
  default: string;
  regex: string;
  required: boolean;
  concealed: boolean;
}

export class SelectOption {
  [key: string]: any;
  id: string;
  label: string;
  default: boolean;
}

export class Select {
  [key: string]: any;
  id: string;
  label: string;
  order: number;
  description: string;
  required: boolean;
  options: SelectOption[];
  constructor() {
    this.options = [];
  }
}

export class ProfileFormModel {
  [key: string]: any;
  scope: string;
  label: string;
  system: string;
  subsystem: string;
  textboxes: Textbox[];
  checkboxes: Checkbox[];
  dropdowns: Select[];
  radios: Select[];
  multiselects: Select[];
  constructor() {
    this.textboxes = [];
    this.checkboxes = [];
    this.dropdowns = [];
    this.radios = [];
    this.multiselects = [];
  }
}
