<div class="mb-3 row">
    <label for="selectedprofile" class="col-sm-2 col-form-label" i18n>External System</label>
    <div class="col-10">
      <ng-select *ngIf="(assetprofiles$ | async) as profiles" class="custom-select" id="selectedprofile" [(ngModel)]="selectedprofile" (change)="onSystemChange()" >
        <ng-option *ngFor="let profile of profiles" [value]="profile">{{ getLabel(profile)}} </ng-option>
      </ng-select>
    </div>
</div>
<div>
<xcm-dynamic-form *ngIf="selectedprofile" [questions]="questions" (onSave)="onSubmit($event)" (onCancel)="onCancelled()"></xcm-dynamic-form>
