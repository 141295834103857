
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Scope } from 'app/shared/models/scope';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'xcm-xcm-config',
  templateUrl: './xcm-config.component.html',
  styleUrls: ['./xcm-config.component.css'],
  standalone: false
})
export class XcmConfigComponent implements OnInit {
  scopes$: Observable<Scope[]>;
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.scopes$ = this.route.data.pipe(map(data => data.grantedScopes));
  }

  onScopeSelected(scope: Scope) {
    this.router.navigate(['scope', scope.label, 'metagroups'], { relativeTo: this.route });
  }
}
