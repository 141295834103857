import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Assetgroup } from 'app/assetgroup/assetgroup-list/assetgroup';
import { ValidationService } from 'app/core/validation.service';

@Component({
  selector: 'xcm-assetgroup-form',
  templateUrl: './assetgroup-form.component.html',
  styleUrls: ['./assetgroup-form.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false
})
export class AssetgroupFormComponent implements OnInit {
  form: FormGroup;
  @Input() assetgroup: Assetgroup;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  private assetgroupPattern = /^[-A-Za-z0-9_]{1,35}$/;
  constructor(private formBuilder: FormBuilder, private validationService: ValidationService) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      label: [
        this.assetgroup.label, [Validators.required, Validators.pattern(this.assetgroupPattern)]
      ],
      cascade: [this.assetgroup.cascade, []]
    });
  }

  onCancelled() {
    this.onCancel.emit(null);
  }

  onSubmit() {
    const assetgroup: Assetgroup = Object.assign(
      {}, this.assetgroup,
      { label: this.form.value.label, cascade: this.form.value.cascade || false });
    this.onSave.emit(assetgroup);
  }

  showSuccessFeedback() {
    return this.validationService.showSuccessFeedback(this.form.get('label'));
  }

  showGlobalErrorFeedback() {
    return this.validationService.showGlobalErrorFeedback(this.form.get('label'));
  }

  showErrorFeedback(errorname: string) {
    return this.validationService.showErrorFeedback(this.form.get('label'), errorname);
  }
}
