import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteData, Scope } from 'app/shared/models/scope';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Metagroup } from './metagroup/metagroup';

@Component({
  selector: 'xcm-assetgroup',
  templateUrl: './assetgroup.component.html',
  styleUrls: ['./assetgroup.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AssetgroupComponent implements OnInit {
  scopes$: Observable<Scope[]>;
  scope: Scope;
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.scopes$ = this.route.data.pipe(map((data) => (data as RouteData).grantedScopes));
  }

  onScopeSelected(scope: Scope) {
    this.scope = scope;
  }

  onMetagroupSelected(metagroup: Metagroup) {
    this.router.navigate(['/scope', this.scope.label, 'metagroup', metagroup.id]);
  }
}
