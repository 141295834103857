
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Assetgroup } from 'app/assetgroup/assetgroup-list/assetgroup';
import { AlertService } from 'app/core/alert.service';
import { AssetgroupService } from 'app/core/assetgroup.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AssetgroupResolver  {
  constructor(
      private assetgroupService: AssetgroupService, private router: Router,
      private alertService: AlertService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<{}|Assetgroup> {
    const id = route.paramMap.get('id') || '-1';
    return this.assetgroupService.getAssetgroup(id).pipe(catchError(() => {
      this.alertService.routechange(true);
      this.router.navigate(['/']);
      return EMPTY;
    }));
  }
}
