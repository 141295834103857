<div class="xcm-content">
    <xcm-header-nav></xcm-header-nav>
    <br> 
    <!--ng-progress></ng-progress 
        used during routing to show progress bar
        https://github.com/MurhafSousli/ngx-progressbar
    -->
    <ng-progress ngProgressRouter/>
    <div class="container-fluid">
    <xcm-alert [timeout]="10000"></xcm-alert>
    <router-outlet></router-outlet>
    </div>
</div>
<xcm-footer></xcm-footer>



