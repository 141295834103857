import { Metagroup } from 'app/assetgroup/metagroup/metagroup';

export class Assetgroup {
  id?: string;
  label: string;
  scope: string;
  cascade: boolean;
  default: boolean;
  metagroup: Metagroup;

  constructor() {
    this.metagroup = new Metagroup();
  }
}
