
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationData } from 'app/shared/models/authorization';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Assetgroup } from '../assetgroup-list/assetgroup';

@Component({
  selector: 'xcm-assetgroup-detail',
  templateUrl: './assetgroup-detail.component.html',
  styleUrls: ['./assetgroup-detail.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false
})
export class AssetgroupDetailComponent implements OnInit {
  @Input() assetgroup: Assetgroup;
  authorizations$: Observable<AuthorizationData>;
  showAssetForm: boolean;
  showEntitiesForm: boolean;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.authorizations$ = this.route.data.pipe(map(data => data.authorizations));
  }

  toggleAsset() {
    this.showAssetForm = !this.showAssetForm;
  }

  toggleEntities() {
    this.showEntitiesForm = !this.showEntitiesForm;
  }

  exitAssetForm() {
    this.showAssetForm = false;
  }

  exitEntitiesForm() {
    this.showEntitiesForm = false;
  }
}
