import { NgModule } from '@angular/core';
import { ProviderProfileRoutingModule } from 'app/provider-profile/provider-profile-routing.module';
import { SharedModule } from 'app/shared/shared.module';

import { CheckboxProfileComponent } from './checkbox-profile/checkbox-profile.component';
import { DropdownProfileComponent } from './dropdown-profile/dropdown-profile.component';
import { MetagroupListComponent } from './metagroup-list/metagroup-list.component';
import { MetagroupProfileComponent } from './metagroup-profile/metagroup-profile.component';
import { MetagroupsOverviewComponent } from './metagroups-overview/metagroups-overview.component';
import { OptionProfileComponent } from './option-profile/option-profile.component';
import { ProfileFormComponent } from './profile-form/profile-form.component';
import { ProfileListComponent } from './profile-list/profile-list.component';
import { ProviderProfileComponent } from './provider-profile/provider-profile.component';
import { TextboxProfileComponent } from './textbox-profile/textbox-profile.component';
import { XcmConfigComponent } from './xcm-config/xcm-config.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [SharedModule, ProviderProfileRoutingModule, NgSelectModule],
  declarations: [
    ProfileFormComponent, TextboxProfileComponent, CheckboxProfileComponent,
    DropdownProfileComponent, OptionProfileComponent, ProviderProfileComponent,
    ProfileListComponent, XcmConfigComponent, MetagroupListComponent, MetagroupsOverviewComponent,
    MetagroupProfileComponent
  ]
})
export class ProviderProfileModule {
}
