import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Alert, AlertType } from 'app/shared/alert/alert';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AlertService {
  private subject = new Subject<Alert | null>();
  private keepAfterRouteChange = true;

  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  getAlert(): Observable<Alert | null> {
    return this.subject.asObservable();
  }

  success(message: string, code = '', keepAfterRouteChange = false) {
    this.alert(AlertType.Success, message, code, keepAfterRouteChange);
  }

  error(message: string, code = '', keepAfterRouteChange = false) {
    this.alert(AlertType.Error, message, code, keepAfterRouteChange);
  }

  info(message: string, code = '', keepAfterRouteChange = false) {
    this.alert(AlertType.Info, message, code, keepAfterRouteChange);
  }

  warn(message: string, code = '', keepAfterRouteChange = false) {
    this.alert(AlertType.Warning, message, code, keepAfterRouteChange);
  }

  alert(type: AlertType, message: string, code: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next(<Alert>{ type: type, code: code, message: message });
  }

  routechange(keepAfterRouteChange: boolean) {
    this.keepAfterRouteChange = keepAfterRouteChange;
  }


  clear() {
    // clear alerts
    this.subject.next(null);
  }
}
