import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetProfile } from 'app/shared/models/asset';

@Component({
  selector: 'xcm-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ProfileListComponent {
  @Input() showButton = true;
  @Input() profiles: AssetProfile[];
  @Output() onDelete: EventEmitter<AssetProfile> = new EventEmitter();
  @Output() onEdit: EventEmitter<AssetProfile> = new EventEmitter();

  constructor() {}

  getProfileInfo(profile: AssetProfile) {
    return profile.label ||
        `${profile.externalSystem.system}` +
        (profile.externalSystem.subsystem ? ` - ${profile.externalSystem.subsystem}` : '');
  }

  delete(profile: AssetProfile) {
    this.onDelete.emit(profile);
  }

  onDetails(profile: AssetProfile) {
    this.onEdit.emit(profile);
  }
}
