import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidationService } from 'app/core/validation.service';

@Component({
  selector: 'xcm-option-profile',
  templateUrl: './option-profile.component.html',
  styleUrls: ['./option-profile.component.css'],
  standalone: false
})
export class OptionProfileComponent {
  @Input() form: FormGroup;
  constructor(private validationService: ValidationService) {}

  showSuccessFeedback(controlname: string) {
    return this.validationService.showSuccessFeedback(this.form.get(controlname));
  }

  showErrorFeedback(controlname: string, errorname: string) {
    return this.validationService.showErrorFeedback(this.form.get(controlname), errorname);
  }

  showGlobalErrorFeedback(controlname: string) {
    return this.validationService.showGlobalErrorFeedback(this.form.get(controlname));
  }
}
