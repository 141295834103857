import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { QuestionBase } from 'app/shared/question-model/question-base';
import { TextboxQuestion } from 'app/shared/question-model/question-textbox';



@Injectable()
export class QuestionControlService {
  static isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  constructor() {}

  toFormGroup(questions: QuestionBase<any>[]) {
    const group: any = {};

    questions.forEach(
      question => {
        const validators: ValidatorFn[] = [];
        if (question.required) {
          validators.push(Validators.required);
        }
        if (question instanceof TextboxQuestion && (question.regex)) {
          validators.push(Validators.pattern(question.regex));
        }
        // BUG email validator not working on optional fields
        // https://github.com/angular/angular/pull/16276
        // Uncomment when fix
        /*if (question instanceof TextboxQuestion && (question.type === 'email')) {
            if ( question.value && (question.value.length >1)) {
                validators.push(Validators.email);
            }
            }
          */


        group[question.id] = new FormControl(question.value, validators);
      }

    );
    return new FormGroup(group);
  }
}
