
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from 'app/core/alert.service';
import { CannedMessage, ResponseWithWarnings } from 'app/core/CannedMessage';
import { EMPTY, Observable } from 'rxjs';

@Injectable()
export class ErrorHandlerService {
  constructor(private alertService: AlertService) {}
  handleError = (err: HttpErrorResponse):
      Observable<any> => {
    const cannedMessage: CannedMessage = {};
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred:', err.error.message);
    } else {
      let alertSent = false;
      cannedMessage.code = err.status;
      cannedMessage.title = err.statusText;
      if (err.status === 401) {
        cannedMessage.title +=
                ' Please logout (Your session must have been expired, or you are no more authorized to work on this area )';
      }
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      const res = err.error;
      if (res) {
        if (typeof res === 'string') {
          try {
            const tmp = JSON.parse(res);
            if (tmp.hasOwnProperty('errors') && tmp['errors'] instanceof Array) {
              tmp['errors'].forEach((message: CannedMessage) => {
                this.alertService.error(message.title || '', `${message.code || ''}`);
                alertSent = true;
              });

            } else if (
              tmp.hasOwnProperty('data') && typeof tmp['data'] === 'object' &&
                    tmp['data'].hasOwnProperty('errors') &&
                    tmp['data']['errors'] instanceof Array) { /** mockup */
              tmp['data']['errors'].forEach((message: CannedMessage) => {
                this.alertService.error(message.title || '', `${message.code || ''}`);
                alertSent = true;
              });
            }
          } catch (e) {
          }
        } else if (typeof res === 'object') {
          if (res.hasOwnProperty('errors') && res['errors'] instanceof Array) {
            res['errors'].forEach((message: CannedMessage) => {
              this.alertService.error(message.title || '', `${message.code || ''}`);
              alertSent = true;
            });
          } else if (
            res.hasOwnProperty('data') && typeof res['data'] === 'object' &&
                  res['data'].hasOwnProperty('errors') && res['data']['errors'] instanceof Array) {
            /** mockup */
            res['data']['errors'].forEach((message: CannedMessage) => {
              this.alertService.error(message.title || '', `${message.code || ''}`);
              alertSent = true;
            });
          }
        }
      }
      if (!alertSent) {
        this.alertService.error(cannedMessage.title || '', `${cannedMessage.code || ''}`);
      }
    }

    return EMPTY;
  }

  handleWarning = (res: ResponseWithWarnings) => {
    if (res && res.hasOwnProperty('warnings') && res['warnings'] instanceof Array) {
      res['warnings'].forEach((message: CannedMessage) => {
        if (message.warningType === 'warning') {
          this.alertService.warn(message.title || '', `${message.code || ''}`);
        } else {
          this.alertService.success(message.title || '', `${message.code || ''}`);
        }
      });
    }
  }
}
