<div class="row mb-5">
  <label for="city-horizontal-lg-sizing" class="col-sm-2 col-form-label">Filter by:</label>
  <div class="col-sm-10">
    <div class="input-group">
      <span class="input-group-text" id="basic-addon1">
        <i class="fa fa-search fa-lg fa-fw" aria-hidden="true"></i>
      </span>
      <input
        #filterBox
        type="text"
        (keyup)="onKeyup(filterBox.value)"
        [(ngModel)]="initialValue"
        class="form-control test-filter"
        aria-describedby="basic-addon1"
        i18n-placeholder
      />
    </div>
  </div>
</div>