import * as ControlType from 'app/shared/question-model/control-type';

import { Option } from './option';
import { QuestionBase } from './question-base';


export class MultiSelectQuestion extends QuestionBase<string[]> {
  options: Option[] = [];

  constructor(options: { [key: string]: any } = {}) {
    super(options);
    if (!this.value) {
      this.value = [];
    }
    this.options = options['options'] || [];
    this.controlType = ControlType.MULTISELECT;
  }
}
