import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Assetgroup } from 'app/assetgroup/assetgroup-list/assetgroup';
import { DeleteUsecase } from 'app/assetgroup/confirm-modal/delete-usecase';
import { AssetgroupService } from 'app/core/assetgroup.service';
import { AuthorizationData } from 'app/shared/models/authorization';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'xcm-assetgroup-header',
  templateUrl: './assetgroup-header.component.html',
  styleUrls: ['./assetgroup-header.component.css'],
  standalone: false
})
export class AssetgroupHeaderComponent implements OnInit {
  @Input() assetgroup: Assetgroup;
  authorizations$: Observable<AuthorizationData>;
  editMode: boolean;
  form: FormGroup;
  constructor(
      private assetgroupService: AssetgroupService, private router: Router,
      private route: ActivatedRoute, private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.authorizations$ = this.route.data.pipe(map(data => data.authorizations));
    this.form = this.formBuilder.group({
      label: [this.assetgroup.label, [Validators.required]],
      cascade: [this.assetgroup.cascade, []]
    });
  }

  exitEditMode() {
    this.editMode = false;
  }

  onEdit() {
    this.editMode = true;
  }

  onCancel() {
    this.editMode = false;
  }

  onDelete() {
    const assetgroupId = this.assetgroup.id;
    if (assetgroupId) {
      this.assetgroupService.confirmDialog(DeleteUsecase.ASSETGROUP)
        .pipe(
          filter((confirm) => confirm),
          switchMap(() => this.assetgroupService.deleteAssetgroup(assetgroupId)))
        .subscribe(() => {
          this.router.navigate(['..'], { relativeTo: this.route });
        });
    }
  }

  onSubmit(assetgroup: Assetgroup) {
    this.assetgroupService.updateAssetgroup(assetgroup).subscribe(() => {
      this.assetgroup = assetgroup;
      this.exitEditMode();
    });
  }
}
