import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { AssetprofileService } from 'app/core/assetprofile.service';
import { ValidationService } from 'app/core/validation.service';

@Component({
  selector: 'xcm-dropdown-profile',
  templateUrl: './dropdown-profile.component.html',
  styleUrls: ['./dropdown-profile.component.css'],
  standalone: false
})
export class DropdownProfileComponent {
  private readonly options = 'options';
  @Input() form: FormGroup;
  constructor(
      private assetProfileService: AssetprofileService,
      private validationService: ValidationService) {}

  removeOption(index: number) {
    const control = <FormArray> this.form.get(this.options);
    control.removeAt(index);
  }

  addOption() {
    const control = <FormArray> this.form.get(this.options);
    control.push(this.assetProfileService.getOptionForm());
  }

  showSuccessFeedback(controlname: string) {
    return this.validationService.showSuccessFeedback(this.form.get(controlname));
  }

  showErrorFeedback(controlname: string, errorname: string) {
    return this.validationService.showErrorFeedback(this.form.get(controlname), errorname);
  }

  showGlobalErrorFeedback(controlname: string) {
    return this.validationService.showGlobalErrorFeedback(this.form.get(controlname));
  }
}
