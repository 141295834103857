<button type="button" (click)="back()" class="btn btn-outline-primary mb-2 mb-md-0 me-2"><i
    class="fa fa-chevron-left fa-fw"></i>Back</button>
<div class="row top-buffer">
  <div class="col">
    <ng-container *ngIf="asset$ | async as asset">
      <ng-container *ngIf="!editMode">
        <h4>Working on {{ asset.profile?.label }} <button type="button" *ngIf="(authorizations$ | async).U_PAYLOAD"
            class="btn btn-outline-primary btn-lg df-btn-icononly df-btn-tertiary fa-pencil mb-2 me-2"
            (click)="$event.preventDefault();onEdit();" aria-label="Edit">
          </button></h4>
        <xcm-asset-detail [asset]="asset"></xcm-asset-detail>
      </ng-container>

      <xcm-asset-edit *ngIf="editMode" [asset]="asset" (onCancel)="exitEditMode()"
        (onSave)="onSave($event)"></xcm-asset-edit>
    </ng-container>
  </div>
</div>