<form [formGroup]="form" class="form" (ngSubmit)="onSubmit()" novalidate>
        <div class="mb-3 row justify-content-center">
          <label for="assetgrouplabel" class="sr-only col-form-label" i18n="@@assetgroup.label">Label</label>
          <div class="col-6">
            <input type="text" formControlName="label" placeholder="Label" 
            class="form-control form-control-lg"
            [class.is-invalid]="showGlobalErrorFeedback()" 
            [class.is-valid]="showSuccessFeedback()"
            id="assetgrouplabel" >
            <div *ngIf="showErrorFeedback('required')" class="invalid-feedback">Label is required</div>
            <div *ngIf="showErrorFeedback('pattern')" class="invalid-feedback">Label can only contain alphanumeric characters, underscore (_) and hyphen (-)</div>
          </div>
        </div>
        <div  class="form-check">
            <label class="form-check-label">
              <input formControlName="cascade" class="form-check-input" type="checkbox"><span i18n="@@assetgroup.cascade">cascade</span>
            </label>
        </div>
        <button type="button" class="btn btn-outline-dark mb-2 mb-md-0 me-2" (click)="onCancelled()" i18n="@@action.editcancel">Cancel</button>            
        <button type="submit" [disabled]="!form.valid" class="btn btn-primary mb-2 mb-md-0 me-2" i18n="@@action.editsave">Save</button>
</form>
