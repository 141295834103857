import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from 'app/core/error-hanlder.service';
import { Authorization } from 'app/shared/models/authorization';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

interface AuthorizationResponse {
  data: Authorization[];
}

@Injectable()
export class AuthorizationService {
  private readonly apiUrl: string = '/v1/xcm/authorizations';
  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {}

  getAuthorizations(scope: string, metagroupId: string): Observable<Authorization[]> {
    return this.http
      .get<AuthorizationResponse>(`${this.apiUrl}?scope=${scope}&metagroupId=${metagroupId}`)
      .pipe(map((res) => res.data || res), catchError(this.errorHandlerService.handleError));
  }

  getBasicAuthorizations(): Observable<Authorization[]> {
    return this.http.get<AuthorizationResponse>(`${this.apiUrl}/basic`)
      .pipe(map((res) => res.data || res), catchError(this.errorHandlerService.handleError));
  }
}
