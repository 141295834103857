
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Metagroup } from 'app/assetgroup/metagroup/metagroup';
import { MetagroupService } from 'app/core/metagroup.service';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class MetagroupsResolver {
  constructor(private metagroupService: MetagroupService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Metagroup[]> {
    const scope = route.paramMap.get('scope') || '';
    return this.metagroupService.getMetagroups(scope).pipe(
      publishReplay(1),
      refCount(),
    );
  }
}
