import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../login/authentication.service';

@Injectable()
export class AuthenticationGuardService  {
  constructor(private authService: AuthenticationService, private router: Router) {}
  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }

    // Store the attempted URL for redirecting
    this.authService.redirectUrl = url;

    // Create a dummy session id
    // const sessionId = 123456789;

    // Set our navigation extras object
    // that contains our global query params and fragment
    /*let navigationExtras: NavigationExtras = {
      queryParams: { 'session_id': sessionId },
      fragment: 'anchor'
    };*/

    // Navigate to the login page with extras
    this.router.navigate(['/login'] /*, navigationExtras*/);
    return false;
  }
}
