<section class="jumbotron">
  <div class="jumbotron-heading d-flex align-items-center justify-content-between">
    <!-- Back Button -->
    <button
      type="button"
      class="btn btn-outline-primary mb-2 mb-md-0 me-2"
      routerLink=".."
      aria-label="Back">
      <i class="fa fa-chevron-left" aria-hidden="true"></i> AssetGroups
    </button>

    <!-- Centered Content -->
    <div class="flex-grow-1 text-center">
      <ng-container *ngIf="!editMode">
        <h1 class="display-4">{{ assetgroup.label }}</h1>
        <p class="lead">
          <span *ngIf="assetgroup.cascade; else deactivated" class="badge rounded-pill bg-success me-2" i18n="@@assetgroup.cascade">CASCADE</span>
          <ng-template #deactivated>
            <span class="badge rounded-pill bg-dark me-2" i18n="@@assetgroup.notcascade">NOT CASCADED</span>
          </ng-template>
        </p>
        <p class="lead">
          <button
            *ngIf="(authorizations$ | async)?.U_ASSETGROUP"
            class="btn btn-lg btn-outline-primary mb-2 mb-md-0 me-2"
            (click)="$event.preventDefault();onEdit();"
            type="button">
            <span class="fa fa-pencil" aria-hidden="true"></span> Edit
          </button>
          <button
            *ngIf="(authorizations$ | async)?.D_ASSETGROUP"
            class="btn btn-outline-danger btn-lg mb-2 mb-md-0 me-2 test-delete-assetprofile"
            (click)="$event.preventDefault();onDelete();"
            type="button">
            <span class="fa fa-trash-can" aria-hidden="true"></span> Delete
          </button>
        </p>
      </ng-container>
      <ng-container *ngIf="editMode">
        <xcm-assetgroup-form
          [assetgroup]="assetgroup"
          (onSave)="onSubmit($event)"
          (onCancel)="onCancel()">
        </xcm-assetgroup-form>
      </ng-container>
    </div>
  </div>
</section>