import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AssetgroupService } from 'app/core/assetgroup.service';
import { AssetprofileService } from 'app/core/assetprofile.service';
import { Asset, AssetPayload, AssetProfile } from 'app/shared/models/asset';
import { QuestionBase } from 'app/shared/question-model/question-base';
import { Observable } from 'rxjs';

@Component({
  selector: 'xcm-asset-create',
  templateUrl: './asset-create.component.html',
  styleUrls: ['./asset-create.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AssetCreateComponent implements OnChanges {
  @Input() assetgroupId: string;
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  selectedprofile: AssetProfile;
  questions: QuestionBase<any>[];
  assetprofiles$: Observable<AssetProfile[]>;

  constructor(
      private assetprofileService: AssetprofileService,
      private assetgroupService: AssetgroupService) {}

  ngOnChanges() {
    this.assetprofiles$ = this.assetprofileService.getAssetProfiles(this.assetgroupId);
  }


  getLabel(profile: AssetProfile) {
    return profile.label ||
        `${profile.externalSystem.system}${
          profile.externalSystem.subsystem ? (` - ${profile.externalSystem.subsystem}`) : ''}`;
  }

  onSystemChange() {
    this.questions = this.assetprofileService.getQuestions(this.selectedprofile);
  }

  onSubmit(payload: { [key: string]: any }) {
    const asset: Asset = new Asset();
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const assetPayload: AssetPayload = new AssetPayload();
        assetPayload.profileElementFormatId = key;
        assetPayload.value = payload[key] ? payload[key].toString() : '';
        asset.payload.push(assetPayload);
      }
    }
    asset.profile = this.selectedprofile;
    this.assetgroupService.addAsset(this.assetgroupId, asset)
      .subscribe(() => this.onSave.emit(null));
  }

  onCancelled() {
    this.onCancel.emit(null);
  }
}
