import { Component } from '@angular/core';

@Component(
  {
    selector: 'xcm-root', templateUrl: './app.component.html', styleUrls: ['./app.component.css'],
    standalone: false
  })
export class AppComponent {
  title = 'xcm';
}
