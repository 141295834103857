
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthorizationService } from 'app/core/authorization.service';
import { AuthorizationData } from 'app/shared/models/authorization';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class AuthorizationsResolver  {
  constructor(private authorizationService: AuthorizationService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<AuthorizationData> {
    let res: Observable<AuthorizationData> = EMPTY;
    if (route.parent && route.parent.data.scope) {
      const id = route.paramMap.get('id') || '-1';
      res = this.authorizationService.getAuthorizations(route.parent.data.scope.label, id)
        .pipe(map(authorizations => {
          const authorizationData = new AuthorizationData();
          authorizations.forEach((authorization) => {
            authorizationData[authorization.label as keyof AuthorizationData] = authorization.allow;
          });
          return authorizationData;
        }));
    }
    return res;
  }
}
