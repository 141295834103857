import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Scope } from 'app/shared/models/scope';

@Component({
  selector: 'xcm-scope',
  templateUrl: './scope.component.html',
  styleUrls: ['./scope.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false
})
export class ScopeComponent implements OnChanges {
  @Input() default: string;
  @Input() scopes: Scope[];
  @Output() onScopeSelected = new EventEmitter<Scope>();
  selectedScope: Scope;


  constructor() {}

  ngOnChanges() {
    if (this.scopes) {
      if (this.default && !(this.default.length === 0)) {
        const defaultScope = this.scopes.find(scope => scope.label === this.default);
        if (defaultScope) {
          this.selectedScope = defaultScope;
        }
      } else if (this.scopes.length === 1) {
        this.selectedScope = this.scopes[0];
        setTimeout(() => this.onScopeSelected.emit(this.selectedScope), 0);
      }
    }
  }

  onScopeChange() {
    this.onScopeSelected.emit(this.selectedScope);
  }
}
