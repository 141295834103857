
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'xcm-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.css'],
  standalone: false
})
export class FilterInputComponent implements OnChanges, OnDestroy {
  @Input() collection: any[];
  @Input() filtercallback: (collection: any[], item: any) => Observable<any[]>;
  @Output() onChange = new EventEmitter<Observable<any[]>>();
  private searchTerms = new Subject<any>();
  private subObject: Subscription;
  initialValue: string;
  constructor() {}

  ngOnChanges() {
    this.cleanup();

    this.subObject =
        this.searchTerms
          .pipe(
            debounceTime(300),  // wait for 300ms pause in events
            // .do(item => console.log('debounce', item))
            distinctUntilChanged(),  // ignore if next search term is same as previous
            switchMap(term => {
              return term ? this.filtercallback(this.collection, term) :
                observableOf(this.collection);
            }),
            tap((items) => {
              this.onChange.emit(observableOf(items));
            }),
            catchError(_error => {
              // TODO: real error handling
              return observableOf<any[]>([]);
            }))
          .subscribe();
  }

  onKeyup(term: string) {
    this.searchTerms.next(term);
  }

  cleanup() {
    this.initialValue = '';
  }

  ngOnDestroy() {
    if (this.subObject) {
      this.subObject.unsubscribe();
    }
  }
}
