<div [formGroup]="form" class="mb-3 row">
  <label [attr.for]="question.id" class="col-2 col-form-label">{{ question.label }}</label>
  <ng-container [ngSwitch]="question.controlType">
    <div *ngSwitchCase="'TEXTBOX'" class="col-10">
      <input [formControlName]="question.id" [id]="question.id" [type]="question.type"
      class="form-control"
      [class.is-invalid]="showGlobalErrorFeedback()" [class.is-valid]="showSuccessFeedback()"
      [placeholder]="question.placeholder"
      attr.aria-describedby="help{{question.id}}"/>
      <small *ngIf="question.description" id="help{{question.id}}" class="form-text text-muted">{{ question.description }}</small>
      <div *ngIf="showErrorFeedback('required')" class="invalid-feedback">Required Field</div>
      <div *ngIf="showErrorFeedback('pattern')" class="invalid-feedback">Field does not match pattern {{ question?.regex }}</div>
    </div>
    <div *ngSwitchCase="'CHECKBOX'" class="form-check col-10">
        <label class="form-check-label form-check-nopadding">
          <input [formControlName]="question.id" [id]="question.id" type="checkbox" 
          class="form-check-input">
          <span>{{ question.label }}</span>
        </label>
    </div>
    <div *ngSwitchCase="'RADIOS'">
      <div *ngFor="let option of question.options" class="form-check">
        <label class="form-check-label">
          <input [formControlName]="question.id" [id]="option.id" type="radio" [value]="option.id"
            class="form-check-input">
            <span>{{ option.value }}</span>
        </label>
      </div>
    </div>
    <div  *ngSwitchCase="'DROPDOWN'" class="col-10">
      <ng-select  class="custom-select" [id]="question.id" [formControlName]="question.id">
        <ng-option *ngIf="!question.required" [value]="null" selected class="text-muted">Choose {{question.label}}</ng-option>
        <ng-option *ngFor="let option of question.options" [value]="option.id">{{option.value}}</ng-option>
      </ng-select>
    </div>

    <div  *ngSwitchCase="'MULTISELECT'" class="col-10">
      <ng-select class="form-control" [id]="question.id" [formControlName]="question.id" multiple>
        <ng-option *ngFor="let option of question.options" [value]="option.id">{{option.value}}</ng-option>
      </ng-select>
    </div>
  </ng-container>
</div>