import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeleteUsecase } from 'app/assetgroup/confirm-modal/delete-usecase';
import { AuthorizationData } from 'app/shared/models/authorization';
import { Observable } from 'rxjs';
import { concat, concatMap, filter, map, publishLast, refCount, switchMap, tap } from 'rxjs/operators';
import { AssetgroupService } from '../../core/assetgroup.service';
import { FilterInputComponent } from '../../shared/filter-input/filter-input.component';
import { Member } from './member';

@Component({
  selector: 'xcm-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css'],
  standalone: false
})
export class MembersComponent implements OnChanges {
  @Input() assetgroup: string;
  @Input() sortAscending = true;
  @ViewChild(FilterInputComponent, { static: false }) private filterinputComponent: FilterInputComponent;
  authorizations$: Observable<AuthorizationData>;
  members$: Observable<Member[]>;
  filteredMembers$: Observable<Member[]>;
  filterCallback = this.assetgroupService.filterEntities;
  processing = false;

  constructor(private assetgroupService: AssetgroupService, private route: ActivatedRoute) {}

  ngOnChanges() {
    if (this.assetgroup) {
      this.authorizations$ = this.route.data.pipe(map(data => data.authorizations));
      this.members$ = this.assetgroupService.getMembers(this.assetgroup)
        .pipe(
          publishLast(), refCount(),
          map((members: Member[]) =>
            members.sort((a, b) => a.label.localeCompare(b.label))));
      this.filteredMembers$ = this.members$;
    }
  }

  onUnlink(member: Member) {
    const memberId = member.id;
    if (memberId) {
      const members$ =
          this.assetgroupService.getMembers(this.assetgroup).pipe(publishLast(), refCount());
      this.assetgroupService.confirmDialog(DeleteUsecase.ASSETGROUP_UNLINK)
        .pipe(
          filter(value => value),  // only continue to process if value == true
          switchMap(
            () => this.assetgroupService.unlinkEntity(this.assetgroup, memberId)
              .pipe(concat(members$))))
        .subscribe(() => {}, () => {}, () => {
          this.members$ =
                this.assetgroupService.getMembers(this.assetgroup).pipe(publishLast(), refCount());
          this.filteredMembers$ = this.members$;
          this.filterinputComponent.cleanup();
        });
    }
  }

  onUnlinkAll() {
    this.assetgroupService.confirmDialog(DeleteUsecase.ASSETGROUP_UNLINK)
      .pipe(
        filter(confirmed => confirmed), tap(() => this.processing = true),
        switchMap(
          () => this.filteredMembers$.pipe(
            switchMap(members => members.filter(member => typeof member.id === 'string')),
            // id is explicitly defined as string, so checked by filter above
            concatMap(
              member => this.assetgroupService.unlinkEntity(
                this.assetgroup, member.id as string)))))
      .subscribe(() => {}, () => {
        this.processing = false
      }, () => {
        this.members$ =
              this.assetgroupService.getMembers(this.assetgroup).pipe(publishLast(), refCount());
        this.filteredMembers$ = this.members$;
        this.processing = false;
        this.filterinputComponent.cleanup();
      });
  }

  onDefault(member: Member) {
    if (member.id) {
      const members$ =
          this.assetgroupService.getMembers(this.assetgroup).pipe(publishLast(), refCount());
      this.assetgroupService.setDefaultEntity(this.assetgroup, member.id, !member.default)
        .pipe(concat(members$))
        .subscribe(() => {}, () => {}, () => {
          this.members$ = members$;
          this.filteredMembers$ = this.members$;
        });
    }
  }

  onSort() {
    this.filteredMembers$ = this.filteredMembers$.pipe(
      map(members => members.sort(
        (a, b) => this.sortAscending ? a.label.localeCompare(b.label) :
          b.label.localeCompare(a.label))));
  }

  setFilteredEntities(members: Observable<Member[]>) {
    this.filteredMembers$ = members;
  }
}
