import * as ControlType from 'app/shared/question-model/control-type';

import { QuestionBase } from './question-base';

export class CheckboxQuestion extends QuestionBase<boolean> {
  type: string;
  help: string;
  constructor(options: { [key: string]: any } = {}) {
    super(options);
    this.controlType = ControlType.CHECKBOX;
    // WARN Do not bind radio type in template,
    // it should be explicitely type="radio" and not [type]="option.type"
    this.type = 'checkbox';
    this.help = options['help'] || '';
  }
}
