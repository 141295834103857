
import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { DfModalService } from '@design-factory/design-factory';
import { Assetgroup } from 'app/assetgroup/assetgroup-list/assetgroup';
import { ConfirmModalComponent } from 'app/assetgroup/confirm-modal/confirm-modal.component';
import { DeleteUsecase } from 'app/assetgroup/confirm-modal/delete-usecase';
import { Member } from 'app/assetgroup/members/member';
import { ErrorHandlerService } from 'app/core/error-hanlder.service';
import { Asset } from 'app/shared/models/asset';
;
import { from as observableFrom, Observable } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';

interface AssetResponse {
  data: Asset[];
  warnings?: any;
}
interface CommonResponse<T> {
  data: T;
}

interface AssetgroupResponse {
  data: Assetgroup[];
}

interface MemberResponse {
  data: Member[];
}

@Injectable()
export class AssetgroupService {
  private readonly apiUrl: string = '/v1/xcm/assetgroups';
  members: Member[];


  constructor(
      private http: HttpClient, private errorHandlerService: ErrorHandlerService,
      private modalService: DfModalService) {}

  getAssets(assetgroupId: string): Observable<Asset[]> {
    return this.http.get<AssetResponse>(`${this.apiUrl}/${assetgroupId}/assets`)
      .pipe(
        map((res) => res.data || (res.warnings ? [] : res)),
        catchError(this.errorHandlerService.handleError));
  }

  getAsset(assetgroupId: string, assetId: string): Observable<Asset> {
    return this.http.get<CommonResponse<Asset>>(`${this.apiUrl}/${assetgroupId}/assets/${assetId}`)
      .pipe(map((res) => res.data || res), catchError(this.errorHandlerService.handleError));
    ;
  }

  getAssetgroups(scope: string, metagroupId: string): Observable<Assetgroup[]> {
    return this.http
      .get<AssetgroupResponse>(`${this.apiUrl}?scope=${scope}&metagroupId=${metagroupId}`)
      .pipe(map((res) => res.data), catchError(this.errorHandlerService.handleError));
  }

  getAssetgroup(assetgroupId: string): Observable<Assetgroup> {
    return this.http.get<CommonResponse<Assetgroup>>(`${this.apiUrl}/${assetgroupId}`)
      .pipe(map((res) => res.data), catchError(this.errorHandlerService.handleError));
  }

  updateAssetgroup(assetgroup: Assetgroup) {
    return this.http.put(`${this.apiUrl}/${assetgroup.id}`, assetgroup)
      .pipe(
        tap(this.errorHandlerService.handleWarning),
        catchError(this.errorHandlerService.handleError));
  }

  addAssetgroup(assetgroup: Assetgroup) {
    return this.http.post(`${this.apiUrl}`, assetgroup)
      .pipe(
        tap(this.errorHandlerService.handleWarning),
        catchError(this.errorHandlerService.handleError));
  }

  getMembers(assetgroupId: string): Observable<Member[]> {
    return this.http.get<MemberResponse>(`${this.apiUrl}/${assetgroupId}/members`)
      .pipe(map((res) => res.data), catchError(this.errorHandlerService.handleError));
    ;
  }

  linkEntity(assetgroupId: string, member: Member) {
    if (isDevMode()) {
      delete member.checked;
      member['assetgroupId'] = assetgroupId as keyof Member;
      return this.http.put(`${this.apiUrl}/${assetgroupId}/members/${member.id}`, member)
        .pipe(catchError(this.errorHandlerService.handleError));
    } else {
      return this.http.post(`${this.apiUrl}/${assetgroupId}/members`, member)
        .pipe(
          tap(this.errorHandlerService.handleWarning),
          catchError(this.errorHandlerService.handleError));
    }
  }

  unlinkEntity(assetgroupId: string, memberId: string) {
    if (isDevMode()) {
      const member = { assetgroupId: 'NaN' };
      return this.http.patch(`${this.apiUrl}/${assetgroupId}/members/${memberId}`, member)
        .pipe(catchError(this.errorHandlerService.handleError));
    } else {
      return this.http.delete(`${this.apiUrl}/${assetgroupId}/members/${memberId}`)
        .pipe(
          tap(this.errorHandlerService.handleWarning),
          catchError(this.errorHandlerService.handleError));
    }
  }

  deleteAsset(assetgroupId: string, assetId: string) {
    return this.http.delete(`${this.apiUrl}/${assetgroupId}/assets/${assetId}`)
      .pipe(
        tap(this.errorHandlerService.handleWarning),
        catchError(this.errorHandlerService.handleError));
  }

  addAsset(assetgroupId: string, asset: Asset) {
    return this.http.post(`${this.apiUrl}/${assetgroupId}/assets`, asset)
      .pipe(
        tap(this.errorHandlerService.handleWarning),
        catchError(this.errorHandlerService.handleError));
  }

  updateAsset(assetgroupId: string, asset: Asset) {
    for (const elementFormat of asset.profile.profileElement) {
      if (elementFormat.concealed) {
        const idx = asset.payload.findIndex(
          (assetPayload) => assetPayload.profileElementFormatId === elementFormat.id);
        if (idx !== -1 && asset.payload[idx].value.startsWith('*****')) {
          // Removing from payload the profileElements that are concealed with at least 5 stars
          // as we don't want them to be updated in the DB
          asset.payload.splice(idx, 1);
        }
      }
    }

    return this.http.put(`${this.apiUrl}/${assetgroupId}/assets/${asset.id}`, asset)
      .pipe(
        tap(this.errorHandlerService.handleWarning),
        catchError(this.errorHandlerService.handleError));
  }

  setDefaultEntity(assetgroupId: string, memberId: string, isDefault: boolean) {
    return this.http
      .patch(`${this.apiUrl}/${assetgroupId}/members/${memberId}`, { default: isDefault })
      .pipe(
        tap(this.errorHandlerService.handleWarning),
        catchError(this.errorHandlerService.handleError));
  }

  deleteAssetgroup(assetgroupId: string) {
    return this.http.delete(`${this.apiUrl}/${assetgroupId}`)
      .pipe(
        tap(this.errorHandlerService.handleWarning),
        catchError(this.errorHandlerService.handleError));
  }


  private async showConfirmDialog(usecase?: DeleteUsecase) {
    let action = false;
    const modalRef = await this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.usecase = usecase;
    try {
      action = await modalRef.result;
    } catch (e) {
      action = false;
    }
    return action;
  }

  confirmDialog(usecase?: DeleteUsecase) {
    return observableFrom(this.showConfirmDialog(usecase));
  }


  filterAssetgroups(assetgroups: Assetgroup[], term: string): Observable<Assetgroup[]> {
    return observableFrom(assetgroups)
      .pipe(
        filter((assetgroup) => assetgroup.label.toLowerCase().startsWith(term.toLowerCase())),
        toArray());
  }

  filterAssets(assets: Asset[], term: string): Observable<Asset[]> {
    return observableFrom(assets).pipe(
      filter((asset) => asset.profile.label.toLowerCase().includes(term.toLowerCase())),
      toArray());
  }

  filterEntities(members: Member[], term: string): Observable<Member[]> {
    return observableFrom(members).pipe(
      filter((member) => member.label.toLowerCase().includes(term.toLowerCase())), toArray());
  }
}
