import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Assetgroup } from 'app/assetgroup/assetgroup-list/assetgroup';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'xcm-assetgroup-view',
  templateUrl: './assetgroup-view.component.html',
  styleUrls: ['./assetgroup-view.component.css'],
  standalone: false
})
export class AssetgroupViewComponent implements OnInit {
  assetgroup$: Observable<Assetgroup>;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.assetgroup$ = this.route.data.pipe(map(data => data.assetgroup));
  }
}
