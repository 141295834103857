import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticationGuardService } from 'app/core/authentication-guard.service';
import { environment } from 'environments/environment';

const routes: Routes = [
  {
    path: 'assetgroup',
    loadChildren: () => import('app/assetgroup/assetgroup.module').then(m => m.AssetgroupModule),
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'provider-profile',
    loadChildren: () => import('app/provider-profile/provider-profile.module').then(m => m.ProviderProfileModule),
    canActivate: [AuthenticationGuardService]
  },
  { path: 'login', loadChildren: () => import('app/login/login.module').then(m => m.LoginModule) },
  { path: '', redirectTo: 'assetgroup', pathMatch: 'full' },
  { path: '**', redirectTo: 'assetgroup', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, { enableTracing: !environment.production, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
