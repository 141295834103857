import { ActionReducerMap } from '@ngrx/store';

import * as fromMetagroup from './metagroup.reducer';
import * as fromScope from './scope.reducer';

export interface AppState {
  scope: fromScope.State;
  metagroup: fromMetagroup.State;
}

export const reducers: ActionReducerMap<AppState> = {
  scope: fromScope.reducer,
  metagroup: fromMetagroup.reducer
}
