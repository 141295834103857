import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from 'app/core/alert.service';
import { Alert, AlertType } from 'app/shared/alert/alert';
import { Subscription } from 'rxjs';

@Component({
  selector: 'xcm-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
  standalone: false
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() timeout: number;
  private alertSub: Subscription;
  alerts: Alert[] = [];
  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.alertSub = this.alertService.getAlert().subscribe((alert: Alert) => {
      if (!alert) {
        // clear alerts when an empty alert is received
        this.alerts = [];
        return;
      }

      // add alert to array unique by code and type
      if (!this.alerts.some((item) => (item.type === alert.type) && (item.code === alert.code))) {
        this.alerts.push(alert);
      }

      // remove alert after
      if (this.timeout) {
        setTimeout(() => this.removeAlert(alert), this.timeout);
      }
    });
  }

  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  alertType(alert: Alert) {
    if (!alert) {
      return;
    }

    // return css class based on alert type
    switch (alert.type) {
      case AlertType.Success:
        return 'success';
      case AlertType.Error:
        return 'danger';
      case AlertType.Info:
        return 'info';
      case AlertType.Warning:
        return 'warning';
    }
  }

  ngOnDestroy() {
    if (this.alertSub) {
      this.alertSub.unsubscribe();
    }
  }
}
