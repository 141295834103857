<ng-container *ngIf="isLoggedIn">
<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <button class="navbar-toggler navbar-toggler-right" type="button" (click)="collapse()" aria-controls="navbarText" [attr.aria-expanded]="isexpanded" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <span class="navbar-brand"><img src="assets/amadeus-logo.png" width="110" alt="amadeus-logo"></span>
  
    <div [ngClass]="{'collapsed' : isexpanded, 'collapse': !isexpanded}" class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav ms-auto">
      </ul>
      <div class="form-inline my-2 my-lg-0">
        
        <span class="navbar-text">
          <i class="fa fa-user"></i> {{userInfo?.login}} ({{userInfo?.organization}}) - {{userInfo?.env}}
        </span>

        <button class="btn btn-primary my-2 my-sm-0 test-logout" type="button" (click)="logout()"><i class="fa fa-power-off fa-lg fa-fw"></i> <span>Logout</span></button>
      </div>
    </div>
</nav>
<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <span class="navbar-brand">eXternal Content Management</span>

  <div [ngClass]="{'collapsed' : isexpanded, 'collapse': !isexpanded}"class="collapse navbar-collapse" id="navbarText">
    <ul class="navbar-nav mr-auto">
      <ng-container *ngIf="scope$ | async as scope; else setuphome"> 
        <ng-container *ngIf="metagroup$ | async as metagroup"> 
          <li class="nav-item" routerLinkActive="active">
              <a class="nav-link" [routerLink]="['scope', scope.label, 'metagroup', metagroup.id]">Home <span class="sr-only">(current)</span></a>
          </li>
        </ng-container>
      </ng-container>
      <ng-template #setuphome>
          <li class="nav-item" routerLinkActive="active">
              <a class="nav-link test-home" routerLink="/assetgroup">Home <span class="sr-only">(current)</span></a>
          </li>
      </ng-template>

      <li *ngIf="(authorizations$ | async)?.R_PROFILE_TAB" class="nav-item" routerLinkActive="active">
          <a class="nav-link test-provider" routerLink="/provider-profile">Provider Profile</a>
      </li>
    </ul>
    <div class="d-flex flex-row align-items-center flex-wrap my-2 my-lg-0">
      <ng-container  *ngIf="scope$ | async as scope"> 
        <ng-container  *ngIf="metagroup$ | async as metagroup">
          <span class="navbar-text"> 
            {{ scope?.description || scope.label }} <ng-container i18n>-</ng-container> {{ metagroup.label }}&nbsp;&nbsp;
          </span>
        </ng-container>
      </ng-container>
    </div>
  </div>
</nav>
</ng-container>