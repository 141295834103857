import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { AssetprofileService } from 'app/core/assetprofile.service';
import { ValidationService } from 'app/core/validation.service';
import { ProfileFormModel } from 'app/provider-profile/profile-form/profile-form-model';
import { AssetProfile } from 'app/shared/models/asset';
import { Scope } from 'app/shared/models/scope';

@Component({
  selector: 'xcm-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.css'],
  standalone: false
})
export class ProfileFormComponent implements OnChanges {
  @Input() assetprofile: AssetProfile;
  @Input() scopes: Scope[];
  @Output() onSave: EventEmitter<AssetProfile> = new EventEmitter();
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  form: FormGroup;

  constructor(
      private assetProfileService: AssetprofileService,
      private validationService: ValidationService) {}

  ngOnChanges() {
    const profileForm = (this.assetprofile) ?
      this.assetProfileService.assetProfileToProfileForm(this.assetprofile) :
      new ProfileFormModel();
    this.form = this.assetprofile ? this.assetProfileService.getProfileForm(profileForm) :
      this.assetProfileService.getProfileForm();
  }

  getScope(scope: Scope) {
    return `${scope.label}${scope.description ? ` - ${scope.description}` : ''}`;
  }

  onSubmit() {
    const profile: ProfileFormModel = this.form.value;
    const assetProfile = this.assetProfileService.profileFormToAssetProfile(profile);
    if (this.assetprofile) {
      assetProfile.id = this.assetprofile.id;
    }
    this.onSave.emit(assetProfile);
  }

  showSuccessFeedback(controlname: string) {
    return this.validationService.showSuccessFeedback(this.form.get(controlname));
  }

  showErrorFeedback(controlname: string, errorname: string) {
    return this.validationService.showErrorFeedback(this.form.get(controlname), errorname);
  }

  onCancelled() {
    this.onCancel.emit();
  }

  addTextbox() {
    const control = <FormArray>(this.form.get('textboxes'));
    control.push(this.assetProfileService.getTextboxForm());
  }

  removeTextbox(index: number) {
    const control = <FormArray> this.form.get('textboxes');
    control.removeAt(index);
  }

  addCheckbox() {
    const control = <FormArray> this.form.get('checkboxes');
    control.push(this.assetProfileService.getCheckboxForm());
  }

  removeCheckbox(index: number) {
    const control = <FormArray> this.form.get('checkboxes');
    control.removeAt(index);
  }

  addDropdown() {
    const control = <FormArray> this.form.get('dropdowns');
    control.push(this.assetProfileService.getSelectForm());
  }

  removeDropdown(index: number) {
    const control = <FormArray> this.form.get('dropdowns');
    control.removeAt(index);
  }

  addMultiselect() {
    const control = <FormArray> this.form.get('multiselects');
    control.push(this.assetProfileService.getMultiSelectForm());
  }

  removeMultiselect(index: number) {
    const control = <FormArray> this.form.get('multiselects');
    control.removeAt(index);
  }

  addRadio() {
    const control = <FormArray> this.form.get('radios');
    control.push(this.assetProfileService.getSelectForm());
  }

  removeRadio(index: number) {
    const control = <FormArray> this.form.get('radios');
    control.removeAt(index);
  }
}
