import * as ControlType from 'app/shared/question-model/control-type';

import { QuestionBase } from './question-base';

export class TextboxQuestion extends QuestionBase<string> {
  type: string;
  placeholder: string;
  description: string;
  regex: string;
  constructor(options: { [key: string]: any } = {}) {
    super(options);
    this.controlType = ControlType.TEXTBOX;
    this.type = options['type'] || 'text';
    this.placeholder = options['placeholder'] || '';
    this.description = options['description'] || '';
    this.regex = options['regex'] || '';
  }
}
